import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import RatingLine from '@magalu/stereo-ui/molecules/RatingLine';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
var RatingLineGroup = function RatingLineGroup(_ref) {
  var staticProps = _ref["static"],
    layout = _ref.layout,
    data = _ref.data;
  var title = staticProps.title,
    isMobile = staticProps.isMobile;
  var widthLinearRating = layout.widthLinearRating,
    widthRating = layout.widthRating,
    columnGapRating = layout.columnGapRating,
    heightProgressRating = layout.heightProgressRating,
    columnGapRatingLineGroup = layout.columnGapRatingLineGroup,
    scoreFontSizeRating = layout.scoreFontSizeRating,
    starSizeRating = layout.starSizeRating;
  var _ref2 = data.productRating || {},
    _ref2$reviewsByRating = _ref2.reviewsByRating,
    reviewsByRating = _ref2$reviewsByRating === void 0 ? [] : _ref2$reviewsByRating,
    _ref2$general = _ref2.general,
    general = _ref2$general === void 0 ? {} : _ref2$general;
  var _ref3 = general || {},
    reviewCount = _ref3.reviewCount;
  var sortedReviewsByRating = _toConsumableArray(reviewsByRating).sort(function (a, b) {
    return b.rating - a.rating;
  });
  return !!(sortedReviewsByRating == null ? void 0 : sortedReviewsByRating.length) && !!reviewCount && React.createElement(React.Fragment, null, !isMobile && React.createElement(Text, {
    py: 3,
    as: "h2",
    fontWeight: "medium",
    fontSize: 1
  }, title), React.createElement(Flex, {
    "data-testid": "rating-line-container",
    flexDirection: "column",
    gap: columnGapRatingLineGroup
  }, sortedReviewsByRating.map(function (item) {
    return React.createElement(RatingLine, {
      key: item.rating,
      count: (item == null ? void 0 : item.total) <= 999 ? "".concat(item == null ? void 0 : item.total) : '+999',
      id: item == null ? void 0 : item.rating,
      max: reviewCount,
      score: item == null ? void 0 : item.total,
      starColor: "#FFCC03",
      lineColor: "#0086ff",
      fontweightScore: "bold",
      heightProgress: heightProgressRating,
      columnGap: columnGapRating,
      width: widthRating,
      starSize: starSizeRating,
      scoreFontSize: scoreFontSizeRating,
      widthProgress: widthLinearRating,
      countColor: "black",
      pointerNone: true
    });
  })));
};
RatingLineGroup.defaultProps = {
  data: {
    productRating: {
      reviewsByRating: []
    }
  },
  layout: {
    scoreFontSizeRating: 1,
    widthLinearRating: '70px',
    widthRating: 40
  },
  "static": {
    isMobile: true,
    title: ''
  }
};
process.env.NODE_ENV !== "production" ? RatingLineGroup.propTypes = {
  data: shape({}),
  layout: shape({
    columnGapRating: number,
    columnGapRatingLineGroup: string,
    heightProgressRating: string,
    scoreFontSizeRating: number,
    starSizeRating: string,
    widthLinearRating: string,
    widthRating: number
  }),
  "static": shape({
    isMobile: bool,
    title: string
  })
} : void 0;
RatingLineGroup.ssr = true;
export default RatingLineGroup;