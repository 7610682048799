import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles/build/utils';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Box from '@magalu/stereo-ui/atoms/Box';
export var ButtonFloatContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  position: fixed;\n  z-index: ", ";\n  min-height: 60px;\n  border-top: 1px solid #eee;\n  border-radius: 4px;\n  box-shadow: 0 18px 25px rgb(0 0 0 / 10%);\n  background-color: ", ";\n  box-sizing: border-box;\n  padding: 1rem;\n  bottom: 0;\n"])), function (props) {
  return themeGet('zIndices.3')(props);
}, function (props) {
  return getPaletteColor('background.white')(props);
});
export var DialogContainer = styled(Dialog)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));