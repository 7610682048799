import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { number, string } from 'prop-types';
import { PromoterDescriptionContainer, SeeMoreButton, ShareIcon } from '../../PromoterHeader.style';
export var PromoterDescription = function PromoterDescription(_ref) {
  var description = _ref.description,
    descriptionLimit = _ref.descriptionLimit;
  if (!description) {
    return null;
  }
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showFullDescription = _useState2[0],
    setShowFullDescription = _useState2[1];
  var isLongDescription = (description == null ? void 0 : description.length) > descriptionLimit;
  return React.createElement(React.Fragment, null, React.createElement(PromoterDescriptionContainer, {
    "data-testid": "promoter-description",
    "aria-expanded": showFullDescription,
    fontSize: 1,
    isOpen: !isLongDescription || showFullDescription,
    mt: 2
  }, description), isLongDescription && React.createElement(SeeMoreButton, {
    "data-testid": "promoter-description-button",
    as: "button",
    fontSize: 1,
    isOpen: showFullDescription,
    onClick: function onClick() {
      return setShowFullDescription(function (prev) {
        return !prev;
      });
    }
  }, React.createElement("span", null, "Ver mais"), React.createElement("span", null, React.createElement(ShareIcon, {
    name: "ChevronDown",
    color: "primary",
    height: 16,
    ml: 1,
    width: 16
  }))));
};
process.env.NODE_ENV !== "production" ? PromoterDescription.propTypes = {
  description: string,
  descriptionLimit: number
} : void 0;
PromoterDescription.defaultProps = {
  description: null,
  descriptionLimit: 60
};
export default PromoterDescription;