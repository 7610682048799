import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { PromoterDescription, SocialShare } from '../PromoterHeaderComponents';
import { BlueStrip, PromoterAvatar, PromoterContainer, PromoterData, PromoterHeaderMobileWrapper, CapitalizedText } from '../../PromoterHeader.style';
var PromoterHeaderMobile = function PromoterHeaderMobile(_ref) {
  var staticProps = _ref.staticProps,
    promoterData = _ref.promoterData;
  var store = staticProps.store,
    descriptionLimit = staticProps.descriptionLimit;
  return React.createElement(PromoterHeaderMobileWrapper, {
    "data-testid": "promoter-header-mobile",
    p: 1,
    width: 1
  }, React.createElement(BlueStrip, null), React.createElement(Flex, {
    alignItems: "flex-start",
    justifyContent: "space-between"
  }, React.createElement(PromoterContainer, {
    isMobile: true
  }, React.createElement(Flex, {
    alignItems: "flex-end",
    justifyContent: "space-between",
    p: 2,
    width: 1,
    minHeight: "110px"
  }, React.createElement(PromoterAvatar, {
    isMobile: true,
    "data-testid": "promoter-avatar",
    src: store == null ? void 0 : store.image,
    alt: "Imagem de perfil do Divulgador",
    size: "94px"
  }), React.createElement(SocialShare, {
    promoterData: promoterData,
    isMobile: true
  })), React.createElement(PromoterData, {
    isMobile: true
  }, React.createElement(CapitalizedText, {
    fontSize: 4,
    fontWeight: "bold"
  }, store == null ? void 0 : store.name), React.createElement(Text, {
    color: "primary",
    fontSize: 2,
    fontWeight: "bold",
    mt: 3
  }, "Influenciador Magalu"), React.createElement(PromoterDescription, {
    description: promoterData.description,
    descriptionLimit: descriptionLimit
  })))));
};
process.env.NODE_ENV !== "production" ? PromoterHeaderMobile.propTypes = {
  promoterData: shape({
    description: string,
    email: string,
    phone: string,
    socialnetwork: arrayOf(shape({
      name: string,
      username: string
    }))
  }),
  staticProps: shape({
    avatar: shape({
      image: string
    }),
    descriptionLimit: number
  })
} : void 0;
PromoterHeaderMobile.defaultProps = {
  promoterData: null,
  staticProps: {}
};
PromoterHeaderMobile.ssr = false;
PromoterHeaderMobile.displayName = 'PromoterHeaderMobile';
export default PromoterHeaderMobile;