import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { PromoterDescription, SocialShare } from '../PromoterHeaderComponents';
import { PromoterAvatar, PromoterAvatarContainer, PromoterContainer, PromoterDataContainer, PromoterWrapper, CapitalizedText } from '../../PromoterHeader.style';
var PromoterHeaderDesktop = function PromoterHeaderDesktop(_ref) {
  var staticProps = _ref.staticProps,
    promoterData = _ref.promoterData;
  var store = staticProps.store,
    descriptionLimit = staticProps.descriptionLimit;
  return React.createElement(PromoterWrapper, {
    "data-testid": "promoter-header-desktop",
    width: 1
  }, React.createElement(PromoterContainer, null, React.createElement(Flex, {
    alignItems: "flex-start"
  }, React.createElement(PromoterAvatarContainer, null, React.createElement(PromoterAvatar, {
    src: store == null ? void 0 : store.image,
    alt: "Imagem de perfil do Divulgador",
    size: "102px"
  })), React.createElement(PromoterDataContainer, {
    justifyContent: "center",
    height: "100%"
  }, React.createElement(CapitalizedText, {
    fontSize: 4,
    fontWeight: "bold",
    textTransform: "capitalize",
    lineHeight: "16px"
  }, store == null ? void 0 : store.name), React.createElement(Text, {
    color: "primary",
    fontSize: 2,
    mt: 2,
    lineHeight: "16px"
  }, "Influenciador Magalu"), React.createElement(PromoterDescription, {
    description: promoterData.description,
    descriptionLimit: descriptionLimit
  }), React.createElement(SocialShare, {
    promoterData: promoterData
  })))));
};
process.env.NODE_ENV !== "production" ? PromoterHeaderDesktop.propTypes = {
  promoterData: shape({
    description: string,
    email: string,
    phone: string,
    socialnetwork: arrayOf(shape({
      name: string,
      username: string
    }))
  }),
  staticProps: shape({
    avatar: shape({
      image: string
    }),
    descriptionLimit: number
  })
} : void 0;
PromoterHeaderDesktop.defaultProps = {
  promoterData: null,
  staticProps: {}
};
PromoterHeaderDesktop.ssr = false;
PromoterHeaderDesktop.displayName = 'PromoterHeaderDesktop';
export default PromoterHeaderDesktop;