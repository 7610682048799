import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var QuestionContainer = styled(Box).attrs(function (props) {
  return _extends({}, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:not(:last-child) {\n    border-bottom: 1px solid #eee;\n  }\n"])));
export var TextQuestionAndAnswer = styled(Flex).attrs(function (props) {
  return _extends({}, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  word-break: break-word;\n"])));