import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import Button from '@magalu/stereo-ui/atoms/Button';
import { SocialShareContainer, ShareIcon } from '../../PromoterHeader.style';
import isValidStaticProp from '../../../../../commons/isValidStaticProp';
var createSocialMedia = {
  email: function email(username) {
    return {
      href: "Mailto:".concat(username),
      icon: 'MailOutline',
      name: 'Email'
    };
  },
  instagram: function instagram(username) {
    return {
      href: "https://instagram.com/".concat(username),
      icon: 'Instagram',
      name: 'Instagram'
    };
  },
  telegram: function telegram(username) {
    return {
      href: "https://t.me/".concat(username),
      icon: 'Telegram',
      name: 'Telegram'
    };
  },
  tiktok: function tiktok(username) {
    return {
      href: "https://www.tiktok.com/@".concat(username),
      icon: 'Tiktok',
      name: 'Tiktok'
    };
  },
  whatsapp: function whatsapp(username) {
    return {
      href: "https://wa.me/55".concat(username.replace('-', '')),
      icon: 'WhatsApp',
      name: 'WhatsApp'
    };
  }
};
var SocialShare = function SocialShare(_ref) {
  var promoterData = _ref.promoterData,
    isMobile = _ref.isMobile;
  if (!promoterData) {
    return null;
  }
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    sectionSocial = _useState2[0],
    setSectionSocial = _useState2[1];
  useEffect(function () {
    var _promoterData$socialN;
    var tempSectionSocial = [];
    if (promoterData.phone) tempSectionSocial.push(createSocialMedia.whatsapp(promoterData.phone));
    if (promoterData.email) tempSectionSocial.push(createSocialMedia.email(promoterData.email));
    promoterData == null ? void 0 : (_promoterData$socialN = promoterData.socialNetworks) == null ? void 0 : _promoterData$socialN.forEach(function (_ref2) {
      var name = _ref2.name,
        username = _ref2.username;
      if (username) tempSectionSocial.push(createSocialMedia[name](username));
    });
    setSectionSocial(tempSectionSocial);
  }, [promoterData]);
  return React.createElement(SocialShareContainer, {
    isMobile: isMobile,
    "data-testid": "social-share"
  }, sectionSocial.map(function (_ref3, index) {
    var href = _ref3.href,
      icon = _ref3.icon,
      name = _ref3.name,
      _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? 'primary' : _ref3$color,
      _ref3$iconColor = _ref3.iconColor,
      iconColor = _ref3$iconColor === void 0 ? '#fff' : _ref3$iconColor,
      customColor = _ref3.customColor;
    return isValidStaticProp(href) && React.createElement(Button, {
      key: "button-".concat(name),
      as: "a",
      title: name,
      centered: false,
      color: color,
      customColor: customColor,
      "data-testid": "social-".concat(name.toLowerCase(), "-button"),
      disabled: false,
      full: false,
      href: href,
      target: "_blank",
      rel: "noopener",
      variation: "fill",
      rounded: true,
      size: "auto",
      height: "28px",
      width: "28px",
      mt: 2,
      mr: sectionSocial.length - 1 !== index ? 2 : 0
    }, React.createElement(ShareIcon, {
      name: icon,
      color: iconColor,
      width: 16,
      height: 16
    }));
  }));
};
process.env.NODE_ENV !== "production" ? SocialShare.propTypes = {
  isMobile: bool,
  promoterData: shape({
    email: string,
    phone: string,
    socialnetwork: arrayOf(shape({
      name: string,
      username: string
    }))
  })
} : void 0;
SocialShare.defaultProps = {
  isMobile: false,
  promoterData: null
};
export default SocialShare;