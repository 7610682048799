import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["headerType"];
import React from 'react';
import { oneOf } from 'prop-types';
import PromoterHeaderDesktop from '../PromoterHeaderDesktop';
import PromoterHeaderMobile from '../PromoterHeaderMobile';
import PromoterHeaderMinimal from '../PromoterHeaderMinimal';
export var PromoterHeaderByType = function PromoterHeaderByType(_ref) {
  var headerType = _ref.headerType,
    rest = _objectWithoutProperties(_ref, _excluded);
  var mappedPromoterHeader = {
    desktop: PromoterHeaderDesktop,
    minimal: PromoterHeaderMinimal,
    mobile: PromoterHeaderMobile
  };
  var CardWrapper = mappedPromoterHeader[headerType];
  return React.createElement(CardWrapper, rest);
};
process.env.NODE_ENV !== "production" ? PromoterHeaderByType.propTypes = {
  headerType: oneOf(['desktop', 'mobile', 'minimal'])
} : void 0;
PromoterHeaderByType.defaultProps = {
  headerType: 'minimal'
};
export default PromoterHeaderByType;