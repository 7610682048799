import React from 'react';
import { shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import RatingLineGroup from '../RatingLineGroup/RatingLineGroup';
import ReviewTotalizers from '../ReviewTotalizers';
var RatingPlusScore = function RatingPlusScore(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"],
    layout = _ref.layout;
  var _ref2 = data.productRating || {},
    _ref2$reviewsByRating = _ref2.reviewsByRating,
    reviewsByRating = _ref2$reviewsByRating === void 0 ? [] : _ref2$reviewsByRating,
    _ref2$general = _ref2.general,
    general = _ref2$general === void 0 ? {} : _ref2$general;
  var _ref3 = general || {},
    reviewCount = _ref3.reviewCount;
  var marginComponet = staticProps.marginComponet;
  return !!(reviewsByRating == null ? void 0 : reviewsByRating.length) && !!reviewCount && React.createElement(Flex, {
    m: marginComponet,
    bg: "background.white",
    "data-testid": "rating-plus-score-container"
  }, React.createElement(ReviewTotalizers, {
    data: data,
    flexDirection: "column",
    showComment: true
  }), React.createElement(RatingLineGroup, {
    data: data,
    layout: layout
  }));
};
RatingPlusScore.defaultProps = {
  data: {
    productRating: {
      general: [],
      reviewsByRating: []
    }
  },
  layout: {
    widthLinearRating: '70px'
  },
  "static": {
    marginComponet: ''
  }
};
process.env.NODE_ENV !== "production" ? RatingPlusScore.propTypes = {
  data: shape({}),
  layout: shape({}),
  "static": shape({
    marginComponet: string
  })
} : void 0;
RatingPlusScore.ssr = true;
export default RatingPlusScore;