import React from 'react';
import { shape } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Text from '@magalu/stereo-ui/atoms/Text';
import { PromoterAvatar, CapitalizedText } from '../../PromoterHeader.style';
var PromoterHeaderMinimal = function PromoterHeaderMinimal(_ref) {
  var staticProps = _ref.staticProps;
  var store = staticProps.store;
  return React.createElement(Grid, {
    gridTemplateColumns: "minmax(8px, 1fr) minmax(auto, 1366px) minmax(8px, 1fr)",
    gridTemplateRows: "82px",
    gridTemplateAreas: " '. content .' ",
    "data-testid": "promoter-header-minimal"
  }, React.createElement(Grid, {
    gridArea: "content",
    padding: "16px 0"
  }, React.createElement(Flex, {
    alignItems: "flex-start"
  }, React.createElement(PromoterAvatar, {
    src: store == null ? void 0 : store.image,
    alt: "Imagem de perfil do Divulgador",
    size: "50px"
  }), React.createElement(Flex, {
    flexDirection: "column",
    justifyContent: "center",
    height: "50px"
  }, React.createElement(CapitalizedText, {
    fontSize: 4,
    fontWeight: "bold",
    mb: 2
  }, store == null ? void 0 : store.name), React.createElement(Text, {
    fontSize: 2
  }, "Influenciador Magalu")))));
};
process.env.NODE_ENV !== "production" ? PromoterHeaderMinimal.propTypes = {
  staticProps: shape({})
} : void 0;
PromoterHeaderMinimal.defaultProps = {
  staticProps: {}
};
export default PromoterHeaderMinimal;