import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { usePromoterBusinessCard } from '@magalu/mixer-graphql';
import PromoterHeaderByType from './components/PromoterHeaderByType';
import { PromoterHeaderContainer } from './PromoterHeader.style';
var PromoterHeader = function PromoterHeader(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var headerType = staticProps.headerType,
    store = staticProps.store;
  var props = {
    staticProps: staticProps,
    structure: structure
  };
  var promoterBusinessCardData = {};
  if (headerType !== 'minimal') {
    promoterBusinessCardData = usePromoterBusinessCard({
      storeId: store == null ? void 0 : store.id
    }).promoterBusinessCardData;
  }
  return React.createElement(PromoterHeaderContainer, null, React.createElement(PromoterHeaderByType, _extends({
    headerType: headerType,
    promoterData: promoterBusinessCardData
  }, props)));
};
process.env.NODE_ENV !== "production" ? PromoterHeader.propTypes = {
  "static": shape({
    headerType: string.isRequired,
    storeId: string
  }).isRequired,
  structure: shape({}).isRequired
} : void 0;
PromoterHeader.ssr = true;
PromoterHeader.displayName = 'PromoterHeader';
export default withLayoutProps(PromoterHeader);