import React from 'react';
import { shape, string } from 'prop-types';
import Review from '@magalu/stereo-ui/molecules/Review';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var Rating = function Rating(_ref) {
  var _product$rating, _review$stats, _product$rating2, _review$stats2;
  var _ref$data = _ref.data,
    product = _ref$data.product,
    review = _ref$data.review,
    StaticProps = _ref["static"];
  var eventClick = StaticProps.eventClick;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var rating = {
    count: (product == null ? void 0 : (_product$rating = product.rating) == null ? void 0 : _product$rating.count) || (review == null ? void 0 : (_review$stats = review.stats) == null ? void 0 : _review$stats.count) || 0,
    score: (product == null ? void 0 : (_product$rating2 = product.rating) == null ? void 0 : _product$rating2.score) || (review == null ? void 0 : (_review$stats2 = review.stats) == null ? void 0 : _review$stats2.average) || 0
  };
  return React.createElement(Review, {
    count: rating == null ? void 0 : rating.count,
    score: rating == null ? void 0 : rating.score,
    width: "20px",
    height: "20px",
    fontSize: "12px",
    cursor: "pointer",
    format: "score-count",
    onClick: function onClick() {
      return publish(eventClick);
    }
  });
};
process.env.NODE_ENV !== "production" ? Rating.propTypes = {
  data: shape({
    product: shape({}),
    review: shape({})
  }),
  "static": shape({
    eventClick: string
  })
} : void 0;
Rating.defaultProps = {
  data: {},
  "static": {}
};
Rating.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  review: {
    query: 'ReviewQuery'
  }
};
Rating.ssr = true;
export default withLayoutProps(Rating);